import React from "react"
import Link from "gatsby-link"
import { dateFormat } from "../utils/dateFormat"
import Layout from "../components/layout"
import ShortText from "../components/shortText"
import { getUser } from "../services/auth"

const NavLink = props => {
  if (!props.test) {
    return <Link to={props.url} className={props.className}></Link>
  } else {
    return <span></span>
  }
}

var newsLinkMap = []
newsLinkMap["Afleysingar"] = "/frettir_a/"
newsLinkMap["Aðrir"] = "/frettir_o/"
newsLinkMap["Leyfishafar"] = "/frettir_l/"
newsLinkMap[undefined] = "/frettir/"

var newsLinkMapEn = []
newsLinkMapEn["Afleysingar"] = "/news_a/"
newsLinkMapEn["Aðrir"] = "/news_o/"
newsLinkMapEn["Leyfishafar"] = "/news_l/"
newsLinkMapEn[undefined] = "/news/"

const IndexPage = ({ pageContext }) => {

  const { group, index, first, last, pageCount, language } = pageContext
  const path = language === "en" ? newsLinkMapEn[getUser().role] : newsLinkMap[getUser().role]
  const previousUrl = index - 1 === 1 ? path : path + (index - 1).toString()
  const nextUrl = path + (index + 1).toString()
  
  //(pageContext)
  //(group)

  var newsGroup;


  
  if(getUser().role){
    newsGroup = getUser().role
  }
  else{
    newsGroup = "Almenningur"
  }

  console.log(group)
  console.log(newsGroup)

  var filteredNews = group.filter(({node}) => (node.acf.visibility.length === 0 || node.acf.visibility.includes(newsGroup)))
  return (
    <Layout language={language}>
      <h1>{language === "en" ? "News" : "Fréttir"}</h1>

      {filteredNews.map(({ node }) => (
        <div key={node.id} className="news-item">
          <p className="date">{dateFormat(node.date)}</p>
          <h3 className="title">{node.title}</h3>
          <ShortText limit="150" content={node.content} />
          <Link className="read-more" to={language === "en" ? "/news/" + node.slug : "/frettir/" + node.slug }>
            {language === "en" ? "Read more" : "Lesa meira"}
          </Link>
        </div>
      ))}
      <div className="pagination">
        <NavLink test={first} url={previousUrl} className="prev-page" />
        {Array.from({ length: pageCount }, (_, i) => (
          <Link
            key={`pagination-number${i + 1}`}
            to={`${path}${i === 0 ? "" : i + 1}`}
          >
            {i + 1}
          </Link>
        ))}

        <NavLink test={last} url={nextUrl} className="next-page" />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query NewsQueryEn {
    wordpressWpNewsItems(acf: { language: { eq: "English" } }) {
      wordpress_id
      type
      title
      template
      path
      slug
      status
      modified
      link
      content
      guid
      id
      acf{
        visibility
      }
    }
  }
`
